<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ updateMode == false ? $t('message.addEtdAdjustment') : $t('message.updateEtdAdjustment') + ": " + etdAdjustment }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="etdAdjustmentForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.origin') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="allCountries"
                                    :rules="[...validationRules.required]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Country.name"
                                    item-value="Country.id"
                                    solo
                                    v-model="EtdAdjustment__country_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.adjustment') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :min="0"
                                    :rules="[...validationRules.required, ...validationRules.number]"
                                    :value="EtdAdjustment__days"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    type="number"
                                    @change="EtdAdjustment__days = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addEtdAdjustment()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveEtdAdjustment()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

export default {
    name: "AddEtdAdjustment",
    props: ['etdAdjustmentId','etdAdjustment','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            rules: {
                days: v => !!v || this.$t('message.required'),
                country: v => !!v || this.$t('message.required')
            },
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('etdadjustment',{
            EtdAdjustment__id: 'EtdAdjustment.id',
            EtdAdjustment__country_id: 'EtdAdjustment.country_id',
            EtdAdjustment__days: 'EtdAdjustment.days',
        },'statePrefix'),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('etdadjustment',{
            createEtdAdjustment: 'createEtdAdjustment',
            getEtdAdjustmentToUpdateById: 'getEtdAdjustmentToUpdateById',
            resetEtdAdjustmentToUpdate: 'resetEtdAdjustmentToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateEtdAdjustment: 'updateEtdAdjustment'
        }),
        ...mapActions('country',{
            getAllCountries: ' getAllCountries'
        }),
        addEtdAdjustment () {
            if(this.$refs.etdAdjustmentForm.validate()) {
                this.loading.add = true
                this.createEtdAdjustment()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.etdAdjustmentAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.etdAdjustmentNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.etdAdjustmentNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.etdAdjustmentNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveEtdAdjustment () {
            if(this.$refs.etdAdjustmentForm.validate()) {
                this.loading.save = true
                this.updateEtdAdjustment()
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.shippingPortUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.shippingPortNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.shippingPortNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.shippingPortNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getEtdAdjustmentToUpdateById(this.etdAdjustmentId)
                        .then( () => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetEtdAdjustmentToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allCountries.length == 0) this.getAllCountries()
    }
}
</script>

<style scoped>

</style>